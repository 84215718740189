import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { FaAddressCard } from "react-icons/fa";
import Background from "./Background";
import { SwitchContext } from "../App";

const AboutMe = () => {
  const [switchValue, setSwitchValue] = useContext(SwitchContext);

  return (
    <div>
      <Background backgroundColor={"#f2edf0"} stroke={"rgb(34, 56, 130)"} />
      <Card
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "70%",
          height: "65%",
          transform: "translate(-50%, -50%)",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 3), 0 6px 20px 0 rgba(0, 0, 0, 3)",
          backgroundColor: "#223982",
          opacity: switchValue !== "false" && switchValue ? "0%" : "100%"
        }}
        className=" text-center"
      >
        <br />
        <Card.Title
          style={{
            color: "#f2edf0",
            fontSize: "calc(1vw + 2vh + 1vmin)",
            marginTop: "-20px"
          }}
        >
          About Me
        </Card.Title>
        <Card.Body>
          <Card.Text
            style={{
              fontSize: "calc(1vw + .5vh + 1vmin)",
              color: "#f2edf0",
              marginTop: "-5px"
            }}
          >
            Frontend Developer and Music Producer.
          </Card.Text>

          <Card.Text
            style={{
              fontSize: "calc(1vw + .5vh + 1vmin)",
              color: "#f2edf0",
              marginTop: "-5px"
            }}
          >
            I discovered my love of programming while studying music production.
            The techniques applied in web development can be employed in other
            crafts, for example music. This idea made me realize the limitless
            possibilities for building applications and functionalities.
          </Card.Text>

          <Card.Text
            style={{
              fontSize: "calc(1vw + .5vh + 1vmin)",
              color: "#f2edf0",
              marginTop: "-5px"
            }}
          >
            My unique background in these highly technical fields provides me a
            competitive advantage when it comes to building user friendly and
            engaging web applications.
          </Card.Text>

          <a
            href="https://learn.co/placid-program-2088/resume"
            target="_blank"
            title="Resume"
            rel="noopener noreferrer"
          >
            <FaAddressCard
              className="icon"
              size="calc(2vw + 3vh + 3vmin)"
              color="#f2edf0"
              style={{
                marginTop: "10px"
              }}
            />
          </a>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AboutMe;
