import React, { useState, useContext } from "react";
import { Navbar, Nav, Card, Button } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainCard from "./components/MainCard";
import Contact from "./components/Contact";
import Projects from "./components/Projects";
import Music from "./components/Music";
import Slider from "@material-ui/core/Button";

// To include the default styles
import "react-rangeslider/lib/index.css";

import AboutMe from "./components/AboutMe";
export const SwitchContext = React.createContext();

const App = () => {
  function useLocalState(localItem) {
    const [loc, setState] = useState(localStorage.getItem(localItem));
    function setLoc(newItem) {
      localStorage.setItem(localItem, newItem);
      setState(newItem);
    }

    return [loc, setLoc];
  }

  const [switchValue, setSwitchValue] = useLocalState("val", true);

  const [sliderOne, setSliderOne] = useState(40);

  return (
    <div>
      <div>
        <SwitchContext.Provider
          value={[switchValue, setSwitchValue]}
          // sliderOne={sliderOne}
        >
          <Navbar
            sticky="top"
            variant="dark"
            // bg="dark
            //     "
            expand="lg"
            style={{ backgroundColor: "#3a3a3a" }}
          >
            <Nav.Link href="/">
              <Navbar.Brand>Luis Ascencio </Navbar.Brand>
            </Nav.Link>

            <Navbar.Brand style={{ marginTop: "12px", marginLeft: "-1%" }}>
              {" "}
              <div className="onoffswitch">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  className="onoffswitch-checkbox"
                  onChange={() => {
                    setSwitchValue(!switchValue);
                  }}
                  checked={JSON.parse(switchValue)}
                  id="myonoffswitch"
                />

                <label className="onoffswitch-label" htmlFor="myonoffswitch">
                  <span className="onoffswitch-inner"></span>
                  <span className="onoffswitch-switch"></span>
                </label>
              </div>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="/aboutme">About me</Nav.Link>
                <Nav.Link href="/projects"> Projects</Nav.Link>
                <Nav.Link href="/contact">Contact</Nav.Link>
                <Nav.Link href="/music">Music</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Router>
            <Switch>
              <Route path="/" exact component={MainCard} />
              <Route exact path="/aboutme" component={AboutMe} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/projects" component={Projects} />
              <Route exact path="/music" component={Music} />
            </Switch>
          </Router>
        </SwitchContext.Provider>
      </div>
    </div>
  );
};

export default App;
