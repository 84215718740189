import React, { useContext } from "react";
import { SwitchContext } from "../App";
import { Card } from "react-bootstrap";
import {
  FaAddressCard,
  FaEnvelope,
  FaMobileAlt,
  FaGithub,
  FaLinkedin
} from "react-icons/fa";

import Background from "./Background";

const Contact = () => {
  const [switchValue, setSwitchValue] = useContext(SwitchContext);

  return (
    <div>
      <Background
        backgroundColor={"rgb(242, 237, 240)"}
        stroke={"rgb(224, 239, 77)"}
      />
      <Card
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "60%",
          height: "60%",
          transform: "translate(-50%, -50%)",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 3), 0 6px 20px 0 rgba(0, 0, 0, 3)",
          backgroundColor: "rgb(224, 239, 77)",
          opacity: switchValue !== "false" && switchValue ? "0%" : "100%"
        }}
        className=" text-center"
      >
        <br />
        <Card.Title
          style={{
            color: "rgb(34, 56, 130)",
            fontSize: "calc(1vw + 2vh + 1vmin)",
            marginTop: "-20px"
          }}
        >
          Contact
        </Card.Title>
        <br />

        <Card.Text
          style={{
            fontSize: "calc(1vw + .5vh + 1vmin)",
            color: "rgb(34, 56, 130)"
            // marginTop: "-5px"
          }}
        >
          <FaEnvelope
            // className="icon"
            size="calc(1vw + 3vh + 3vmin)"
            color="rgb(34, 56, 130)"
          />
        </Card.Text>
        <Card.Text
          style={{
            fontSize: "calc(1vw + .5vh + 1vmin)",
            color: "rgb(34, 56, 130)",
            marginTop: "-10px"
          }}
        >
          luismiguelascencio@gmail.com
        </Card.Text>
        <br />

        <Card.Text
          style={{
            fontSize: "calc(1vw + .5vh + 1vmin)",
            color: "#f2edf0"
            // marginTop: "-5px"
          }}
        >
          <FaMobileAlt
            // className="icon"
            size="calc(1vw + 3vh + 3vmin)"
            color="rgb(34, 56, 130)"
          />
        </Card.Text>
        <Card.Text
          style={{
            fontSize: "calc(1vw + .5vh + 1vmin)",
            color: "rgb(34, 56, 130)",
            marginTop: "-10px"
          }}
        >
          +1 (646) 538-8534
        </Card.Text>
        <br />
        <Card.Body>
          <a
            href="https://learn.co/placid-program-2088/resume"
            target="_blank"
            title="Resume"
            rel="noopener noreferrer"
          >
            <FaAddressCard
              className="icon"
              size="calc(1vw + 2vh + 2vmin)"
              color="rgb(34, 56, 130)"
              style={{
                margin: "10px"
              }}
            />
          </a>

          <a
            href="https://github.com/LuisAscencio"
            target="_blank"
            rel="noopener noreferrer"
            title="Github"
          >
            <FaGithub
              className="icon"
              size="calc(1vw + 2vh + 2vmin)"
              color="rgb(34, 56, 130)"
              style={{
                margin: "10px"
              }}
            />
          </a>

          <a
            href="https://www.linkedin.com/in/luis-ascencio-9b9a57112/"
            target="_blank"
            rel="noopener noreferrer"
            title="LinkedIn"
          >
            <FaLinkedin
              className="icon"
              size="calc(1vw + 2vh + 2vmin)"
              color="rgb(34, 56, 130)"
              style={{
                margin: "10px"
              }}
            />
          </a>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Contact;
