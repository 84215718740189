import React, { useContext } from "react";
import ReactPlayer from "react-player";
import { Card } from "react-bootstrap";

import Background from "./Background";
import { FaBandcamp, FaSoundcloud, FaHeadphones } from "react-icons/fa";
import { SwitchContext } from "../App";

const Music = () => {
  const [switchValue, setSwitchValue, sliderOne] = useContext(SwitchContext);
  console.log(sliderOne);

  return (
    <div>
      <Background
        backgroundColor={"#223982"}
        stroke={"rgb(224, 239, 77)"}
        sliderOne={sliderOne}
      />

      <Card
        style={{
          marginTop: "20px",
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "70%",
          height: "80%",
          transform: "translate(-50%, -50%)",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 3), 0 6px 20px 0 rgba(0, 0, 0, 3)",
          backgroundColor: "rgb(224, 239, 77)",
          opacity: switchValue !== "false" && switchValue ? "0%" : "100%"
        }}
        className=" text-center"
      >
        <br />

        <Card.Title
          style={{
            marginTop: "-20px",
            color: "#223982",
            fontSize: "calc(2vw + 2vh + 1vmin)",
            left: "50%",
            textAlign: "center"

            //   transform: "translate(-50%)"
          }}
        >
          Music
        </Card.Title>

        <Card.Body>
          <ReactPlayer
            url="https://soundcloud.com/l-g-r/sets/favs"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              /* bring your own prefixes */
              transform: "translate(-50%, -50%)",
              padding: "30px",
              marginTop: "4px"
            }}
            // light={true}
            width={"90%"}
            height={"87%"}
          />
        </Card.Body>
        <Card.Text style={{ margin: "-.1%" }}>
          <a
            href="http://reycoliman.bandcamp.com/album/metamodern-cuts"
            target="_blank"
            title="Bandcamp"
            rel="noopener noreferrer"
          >
            <FaBandcamp
              className="icon"
              size="calc(1vw + 2vh + 2vmin)"
              color="#223982"
              style={{
                marginTop: "5px",
                marginBottom: "3px",
                margin: "10px",
                marginRight: "40px"
              }}
            />
          </a>
          <a
            href="https://soundcloud.com/l-g-r/tracks"
            target="_blank"
            title="Soundcloud"
            rel="noopener noreferrer"
          >
            <FaSoundcloud
              className="icon"
              size="calc(1vw + 2vh + 2vmin)"
              color="#223982"
              style={{
                marginTop: "5px",
                marginBottom: "3px",
                margin: "10px"
              }}
            />
          </a>
          <a
            href="https://www.beatport.com/release/volcanic-archipelago-ep/1651344"
            target="_blank"
            title="Beatport"
            rel="noopener noreferrer"
          >
            <FaHeadphones
              className="icon"
              size="calc(1vw + 2vh + 2vmin)"
              color="#223982"
              style={{
                marginTop: "5px",
                marginBottom: "3px",
                margin: "10px",
                marginLeft: "40px"
              }}
            />
          </a>
        </Card.Text>
      </Card>
    </div>
  );
};

export default Music;
