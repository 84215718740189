import React, { useContext } from "react";
import { Card, CardDeck } from "react-bootstrap";
import { FaGlobe, FaGithub, FaEye } from "react-icons/fa";
import { SwitchContext } from "../App";
import Background from "./Background";

const Projects = () => {
  const [switchValue, setSwitchValue] = useContext(SwitchContext);

  return (
    <div>
      <Background
        backgroundColor={"rgb(224, 239, 77)"}
        stroke={"rgb(242, 237, 240)"}
      />
      <div
        style={{
          opacity: switchValue !== "false" && switchValue ? "0%" : "100%"
        }}
      >
        <h4
          style={{
            paddingTop: "10px",
            // borderTop: "30px",
            color: "rgb(34, 56, 130)",
            fontSize: "calc(2vw + 2vh + 2vmin)",
            left: "50%",
            textAlign: "center"
            //   transform: "translate(-50%)"
          }}
        >
          Projects
        </h4>

        <CardDeck>
          <Card
            style={{
              // position: "fixed",
              // top: "50%",
              // left: "50%",
              // width: "60%",
              // height: "60%",
              // transform: "translate(-50%, -50%)",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 3), 0 6px 20px 0 rgba(0, 0, 0, 3)",
              backgroundColor: "rgb(242, 237, 240)",
              margin: "30px",
              marginTop: "1%"
            }}
          >
            <Card.Img
              variant="top"
              src="https://media.giphy.com/media/J5MoEWRrDM4gy6hA2k/giphy.gif"
            />
            <Card.Body>
              <Card.Title
                style={{
                  fontSize: "calc(1vw + 1vh + 1vmin)",
                  color: "#3a3a3a"
                }}
              >
                Polymetric Drum Machine
              </Card.Title>
              <Card.Text
                style={{
                  fontSize: "calc(.5vw + .5vh + 1vmin)",
                  color: "#3a3a3a"
                }}
              >
                Drum machine with variable sequencer length to create polymetric
                rhythms. Real time sound design capabilities for unlimited
                synthesizable sounds. Save or recall your sound kits and
                sequences.
              </Card.Text>
              <a
                href="https://github.com/LuisAscencio/react-drum-machine"
                target="_blank"
                rel="noopener noreferrer"
                title="Project Github"
              >
                <FaGithub
                  className="icon"
                  size="calc(2vw + 1vh + 2vmin)"
                  color="rgb(34, 56, 130)"
                  style={{
                    margin: "15px"
                  }}
                />
              </a>
              <a
                href="https://react-drum-machine-6f184.firebaseapp.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Website"
              >
                <FaGlobe
                  className="icon"
                  size="calc(2vw + 1vh + 2vmin)"
                  color="rgb(34, 56, 130)"
                  style={{
                    margin: "15px"
                  }}
                />
              </a>
              <a
                href="https://www.youtube.com/playlist?list=PLXZ6GT7mGw78P0qSc2RnJC4ZUTDKLogth"
                target="_blank"
                rel="noopener noreferrer"
                title="Demo"
              >
                <FaEye
                  className="icon"
                  size="calc(2vw + 1vh + 2vmin)"
                  color="rgb(34, 56, 130)"
                  style={{
                    margin: "15px"
                  }}
                />
              </a>
            </Card.Body>
          </Card>
          <Card
            style={{
              // position: "fixed",
              // top: "50%",
              // left: "50%",
              // width: "60%",
              // height: "60%",
              // transform: "translate(-50%, -50%)",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 3), 0 6px 20px 0 rgba(0, 0, 0, 3)",
              backgroundColor: "rgb(242, 237, 240)",
              margin: "30px",
              marginTop: "1%"
            }}
          >
            <Card.Img
              variant="top"
              src="https://media.giphy.com/media/cMF0WrZPljS5c91GIO/giphy.gif"
            />
            <Card.Body>
              <Card.Title
                style={{
                  fontSize: "calc(1vw + 1vh + 1vmin)",
                  color: "#3a3a3a"
                }}
              >
                Tec Italy NYC CRM
              </Card.Title>
              <Card.Text
                style={{
                  fontSize: "calc(.5vw + .5vh + 1vmin)",
                  color: "#3a3a3a"
                }}
              >
                CRM that tracks daily visits to customers. Dashboard to
                visualize sales and visits. Search your visit entries on the
                visit list. Get directions to the customer location with the
                google maps implementation.
              </Card.Text>
              <a
                href="https://github.com/LuisAscencio/mycrm"
                target="_blank"
                rel="noopener noreferrer"
                title="Project Github"
              >
                <FaGithub
                  className="icon"
                  size="calc(2vw + 1vh + 2vmin)"
                  color="rgb(34, 56, 130)"
                  style={{
                    margin: "15px"
                  }}
                />
              </a>
              <a
                href="https://mycrm-e3f3d.firebaseapp.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Website"
              >
                <FaGlobe
                  className="icon"
                  size="calc(2vw + 1vh + 2vmin)"
                  color="rgb(34, 56, 130)"
                  style={{
                    margin: "15px"
                  }}
                />
              </a>
              <a
                href="https://youtu.be/xO_Fc_mgqg8"
                target="_blank"
                rel="noopener noreferrer"
                title="Demo"
              >
                <FaEye
                  className="icon"
                  size="calc(2vw + 1vh + 2vmin)"
                  color="rgb(34, 56, 130)"
                  style={{
                    margin: "15px"
                  }}
                />
              </a>
            </Card.Body>
          </Card>
          <Card
            style={{
              // position: "fixed",
              // top: "50%",
              // left: "50%",
              // width: "60%",
              // height: "60%",
              // transform: "translate(-50%, -50%)",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 3), 0 6px 20px 0 rgba(0, 0, 0, 3)",
              backgroundColor: "rgb(242, 237, 240)",
              margin: "30px",
              marginTop: "1%"
            }}
          >
            <Card.Img
              variant="top"
              src="https://media.giphy.com/media/Y4t9JM3vMEqqn1i8K1/giphy.gif"
            />
            <Card.Body>
              <Card.Title
                style={{
                  fontSize: "calc(1vw + 1vh + 1vmin)",
                  color: "#3a3a3a"
                }}
              >
                Recipes
              </Card.Title>
              <Card.Text
                style={{
                  fontSize: "calc(.5vw + .5vh + 1vmin)",
                  color: "#3a3a3a"
                }}
              >
                Recipes collection. Browse thousands different recipes from the
                implemented API. Get recipe suggestion generated randomly for
                you. Save your own recipes with photo for later use. Find your
                saved recipes with the search bar.
              </Card.Text>

              <a
                href="https://github.com/LuisAscencio/recipes"
                target="_blank"
                rel="noopener noreferrer"
                title="Project Github"
              >
                <FaGithub
                  className="icon"
                  size="calc(2vw + 1vh + 2vmin)"
                  color="rgb(34, 56, 130)"
                  style={{
                    margin: "15px"
                  }}
                />
              </a>
              <a
                href="https://myrecipe-a7224.firebaseapp.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Website"
              >
                <FaGlobe
                  className="icon"
                  size="calc(2vw + 1vh + 2vmin)"
                  color="rgb(34, 56, 130)"
                  style={{
                    margin: "15px"
                  }}
                />
              </a>
              <a
                href="https://youtu.be/HQpc0kRo9ss"
                target="_blank"
                rel="noopener noreferrer"
                title="Demo"
              >
                <FaEye
                  className="icon"
                  size="calc(2vw + 1vh + 2vmin)"
                  color="rgb(34, 56, 130)"
                  style={{
                    margin: "15px"
                  }}
                />
              </a>
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
    </div>
  );
};

export default Projects;
