import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { FaGithub, FaLinkedin, FaAddressCard } from "react-icons/fa";
import Background from "./Background";
import { SwitchContext } from "../App";

const MainCard = () => {
  const [switchValue, setSwitchValue] = useContext(SwitchContext);

  return (
    <div>
      <Background backgroundColor={"#223982"} stroke={"rgb(242, 237, 240)"} />

      <Card
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "70%",
          height: "45%",
          transform: "translate(-50%, -50%)",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 3), 0 6px 20px 0 rgba(0, 0, 0, 3)",
          backgroundColor: "#f2edf0",

          opacity: switchValue !== "false" && switchValue ? "0%" : "100%"
        }}
        className=" text-center"
      >
        <Card.Body>
          <Card.Text
            style={{ fontSize: "calc(2vw + 2vh + 1vmin)", color: "#3a3a3a" }}
          >
            Hello! My Name Is
          </Card.Text>
          <br />
          <Card.Title
            style={{
              color: "#223982",
              fontSize: "calc(2vw + 4vh + 2vmin)",
              marginTop: "-25px"
            }}
          >
            Luis Ascencio
          </Card.Title>
          <Card.Text
            style={{ fontSize: "calc(1vw + 1vh + 1vmin)", color: "#3a3a3a" }}
          >
            Front-end Developer
          </Card.Text>

          <a
            href="https://github.com/LuisAscencio"
            target="_blank"
            rel="noopener noreferrer"
            title="Github"
          >
            {/* <ShakeHard> */}
            <FaGithub
              className="icon"
              size="calc(2vw + 2vh + 2vmin)"
              color="#223982"
              style={{
                marginTop: "calc(.01vw + .02vh + .2vmin)"
              }}
            />
            {/* </ShakeHard> */}
          </a>

          <a
            href="https://www.linkedin.com/in/luis-ascencio-9b9a57112/"
            target="_blank"
            rel="noopener noreferrer"
            title="LinkedIn"
          >
            <FaLinkedin
              className="icon"
              size="calc(2vw + 2vh + 2vmin)"
              color="#223982"
              style={{
                marginLeft: "40px",
                marginTop: "2px",
                marginRight: "40px"
              }}
            />
          </a>

          <a
            href="https://learn.co/placid-program-2088/resume"
            target="_blank"
            title="Resume"
            rel="noopener noreferrer"
          >
            <FaAddressCard
              className="icon"
              size="calc(2vw + 2vh + 2vmin)"
              color="#223982"
              style={{
                marginTop: "2px"
              }}
            />
          </a>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MainCard;
