import React, { Component } from "react";
import Sketch from "react-p5";
var faker = require("faker");

export default class background extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    items: [0.02, 0.9, 0.5, 0.3, 0.4, 0.7][Math.floor(Math.random() * 6)],
    stW: faker.random.number({ min: 1, max: 23 }),
    spacing: faker.random.number({ min: 20, max: 100 })
  };
  x = 0;
  y = 0;

  spacing = this.state.spacing;

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props) {
      return true;
    }
  }

  setup = (p5, canvasParentRef) => {
    console.log("from bg", this.props.sliderOne);
    p5.createCanvas(window.innerWidth, window.innerHeight).parent(
      canvasParentRef
    );
    p5.background(this.props.backgroundColor);
  };

  windowResized = () => {
    window.location.reload(true);
  };

  draw = p5 => {
    const patternDraw = () => {
      p5.stroke(this.props.stroke);
      p5.strokeWeight(this.state.stW);
      p5.frameRate(90);

      if (Math.random(1) < this.state.items) {
        p5.line(this.x, this.y, this.x + this.spacing, this.y + this.spacing);
        p5.line(this.x, this.y, this.x + this.spacing, this.y + this.spacing);

        // p5.line(
        //   window.innerWidth - this.x,
        //   window.innerHeight - this.y,
        //   window.innerWidth - this.x + this.spacing,
        //   window.innerHeight - this.y + this.spacing
        // );
        // p5.line(
        //   window.innerWidth - this.x,
        //   window.innerHeight - this.y,
        //   window.innerWidth - this.x + this.spacing,
        //   window.innerHeight - this.y + this.spacing
        // );
      } else {
        p5.line(this.x, this.y + this.spacing, this.x + this.spacing, this.y);
        // p5.line(
        //   window.innerWidth - this.x,
        //   window.innerHeight - this.y + this.spacing,
        //   window.innerWidth - this.x + this.spacing,
        //   window.innerHeight - this.y
        // );
      }
      this.x = this.x + this.spacing;
      if (this.x > window.innerWidth) {
        this.x = 0;
        this.y = this.y + this.spacing;
      }
    };

    if (this.y < window.innerHeight) {
      patternDraw();
    }
  };

  render() {
    return (
      <div>
        <Sketch
          style={{ margin: "auto", position: "fixed ", zIndex: "-1" }}
          setup={this.setup}
          draw={this.draw}
          windowResized={this.windowResized}
        />
      </div>
    );
  }
}
